angular.module('dnecom.frontend.mobile')
    .directive('doubleItTimerMobile', function() {
        return {
            link: function(scope, element) {
                var timerTo = parseInt(TemplateData.value('doubleItTimerDuration'));

                function initialize() {
                    if (timerTo) {
                        var d = new Date();
                        d.setTime(d.getTime() + timerTo*1000);

                        // https://github.com/hilios/jQuery.countdown manual
                        $(element)
                            .countdown(d, function(event) {
                                $(this).html(
                                    event.strftime('%M') + 'm ' +
                                    event.strftime('%S') + 's'
                                );
                            });
                    }
                }

                initialize();
            }
        }
    });