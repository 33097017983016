angular
    .module('dnecom.frontend.mobile')
    .controller('HomeController', ["$scope", "$http", "GaUtils", "$window", function ($scope, $http, GaUtils, $window) {
        $scope.products = TemplateData.value('products');

        $scope.current_page = 1;

        $scope.hasMore = $scope.products.length >= 10;

        $scope.loadMore = function () {
            $scope.current_page++;

            $http
                .get(Routing.generate('stores_fe_api_product_index', { page: $scope.current_page }))
                .then(function (response) {
                    if (response.data.success) {
                        angular.forEach(response.data.products, function (item) {
                            $scope.products.push(item);
                        });

                        $scope.hasMore = response.data.products.length >= 10;
                    }
                });
        };

        $scope.trackClick = function (product, position, $event) {
            GaUtils.trackClick(GaUtils.makeTrackData(product, position), "homepage");
            if ($event && $event.currentTarget && $event.currentTarget.href) {
                $window.location.href = $event.currentTarget.href;
            }
        }
    }])
;
