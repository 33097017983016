angular
    .module('dnecom.frontend.mobile')
    .controller('FeaturedController', ["$scope", "$http", "GaUtils", function ($scope, $http, GaUtils) {
        $scope.products = TemplateData.value('products');
        $scope.otherProducts = [];

        $scope.current_page = 1;
        $scope.otherProductsCurrentPage = 0;

        $scope.hasMore = $scope.products.length >= 10;
        $scope.hasMoreOther = $scope.otherProducts.length >= 10;

        $scope.loadMore = function () {
            $scope.current_page++;

            $http
                .get(Routing.generate('stores_fe_api_product_featured_or_not', { page: $scope.current_page, mobile: 1, featured: 1 }))
                .then(function (response) {
                    if (response.data.success) {
                        angular.forEach(response.data.products, function (item) {
                            $scope.products.push(item);
                        });

                        $scope.hasMore = response.data.products.length >= 10;
                    }
                });
        };

        $scope.loadOtherProducts = function () {
            $scope.otherProductsCurrentPage++;

            $http
                .get(Routing.generate('stores_fe_api_product_featured_or_not', { page: $scope.otherProductsCurrentPage, mobile: 1, featured: 0 }))
                .then(function (response) {
                    if (response.data.success) {
                        angular.forEach(response.data.products, function (item) {
                            $scope.otherProducts.push(item);
                        });

                        $scope.hasMoreOther = response.data.products.length >= 10;
                    }
                });
        };

        $scope.trackClick = function (product, position) {
            GaUtils.trackClick(GaUtils.makeTrackData(product, position), "featured");
        }
    }])
;
