angular.module('dnecom.frontend.mobile')
    .directive('showHideZoomButton', ["$compile", "$timeout", function($compile, $timeout) {
        return {
            link: function(scope, element, attributes) {
                scope.$on(
                    "product.zoom.enabled",
                    function handleZoomEnabledEvent(event, slide) {
                        var t = $timeout(function(){
                            var zoomContainer = angular.element(document.getElementById(slide + '-zoomContainer'));
                            if (!document.getElementById(slide + '-removezoomcontainer')) {
                                var myDiv = angular.element('<div class="product-image-zoom-buttons-container" id="'+slide+'-removezoomcontainer"><span ng-mobile-click="removeZoom(\'' + slide + '\')" class="fa fa-search-minus"></span></div>');
                                $compile(myDiv)(scope);
                                zoomContainer.prepend(myDiv);
                            }
                        }, 1000);
                    }
                );
            }
        }
    }]);
