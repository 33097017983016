angular
    .module('dnecom.frontend.mobile')
    .controller('RelatedProductsController', ["$scope", "$http", "GaUtils", "$window", function ($scope, $http, GaUtils, $window) {
        $scope.products = TemplateData.value('products');
        $scope.trackClick = function (product, position, $event) {
            GaUtils.trackClick(GaUtils.makeTrackData(product, position), "homepage");
            if ($event && $event.currentTarget && $event.currentTarget.href) {
                $window.location.href = $event.currentTarget.href;
            }
        }
    }])
;
