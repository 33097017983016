angular
    .module('dnecom.frontend.mobile')
    .controller('CheckoutReviewController', ["$scope", "$http", "$timeout", "CartUtils", "RequestUtils", "ShippingUtils", "Cart", "CustomerUtils", function ($scope, $http, $timeout, CartUtils, RequestUtils, ShippingUtils, Cart, CustomerUtils) {
        $scope.cart = { items: [] };
        $scope.utils = CartUtils;

        $scope.shippingCost = 0;
        $scope.shippingLoading = true;
        $scope.cartLoading = true;
        $scope.paymentLoading = false;

        $scope.customer = TemplateData.value('customer');

        $scope.zones = [];

        $scope.shippingUtils = ShippingUtils;

        $scope.showOrderInfo = false;

        Cart.get().then(function (cart) {
            $scope.cart = cart;

            Cart.calculateShipping($scope.customer.country, $scope.customer.state, cart.hash).then(function (cost) {
                $scope.shippingCost = cost;
            }).finally(function () {
                $scope.shippingLoading = false;
            });
        }, function () {
            $scope.shippingLoading = false;
        }).finally(function () {
            $scope.cartLoading = false;
        });

        $scope.editShipping = function (url) {
            RequestUtils.post(url, { cart: $scope.cart.hash });
        };

        $scope.editCart = function (url) {
            RequestUtils.post(url, { cart: $scope.cart.hash });
        };

        $scope.preparePayment = function (url) {
            $scope.paymentLoading = true;
            RequestUtils.post(url, { cart: $scope.cart.hash, customer: CustomerUtils.get()});
        };

        $scope.toggleOrderInfo = function() {
            $scope.showOrderInfo = !$scope.showOrderInfo;
        };
    }]);