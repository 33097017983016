// workaround for angular-touch bug - https://github.com/angular/angular.js/issues/5307
angular.module('dnecom.frontend.mobile').directive('mobileLink', function () {
    return function($scope, element, attrs) {
        // ngTouch prevents links from executing when an ng-click is present, so we need to trigger the link in those instances
        if (Modernizr.touch && typeof attrs.ngClick !== 'undefined') {
            var touchMoved = false;

            element.on('touchstart', function(event) {
                touchMoved = false;
            });

            element.on('touchmove', function(event) {
                touchMoved = true;
            });

            element.on('touchend', function(event) {
                if (!touchMoved) {
                    if (typeof element[0].click === 'function') {
                        element[0].click();
                    }
                }
            });
        }
    }
});