jQuery(document).ready(function ($) {
    // Gotop on scroll hook
    var $document = $(document);
    $document.scroll(function () {
        var y = $document.scrollTop();

        if (y > 600) {
            $('.go-top-container').fadeIn();
        } else {
            $('.go-top-container').fadeOut();
        }
    });

    var $body = $('html, body');
    $('.go-top-btn').on('click', function (event) {
        event.preventDefault();
        $body.stop().animate({scrollTop: 0}, '500', 'swing');
    });

    $(document).on('click', '.popover .close', function () {
        $(this).parents('.popover').popover('hide');
    });

    var fixCanvasStyle = function () {
        $('.canvas').css({
            'padding-top': ($('.signify-top-bar').outerHeight() + $('.navbar').outerHeight() + $('.search-mobile-container').outerHeight())  + 'px',
            'padding-bottom': $('.ies-mobile-footer').outerHeight() + 'px'
        });

        $('.navbar, .navmenu').css({
            'margin-top': $('.signify-top-bar').outerHeight() + 'px'
        });
        $('.search-mobile-container').css({
            'top': $('.signify-top-bar').outerHeight() + $('.navbar').outerHeight() + 'px'
        });

    };

    fixCanvasStyle();
    $(window).resize(fixCanvasStyle);
    setInterval(fixCanvasStyle, 3000);
    $('body').on('signify-top-bar.init', fixCanvasStyle);

    $('.navmenu-fixed-left').on('shown.bs.offcanvas', function () {
        $('body').addClass('sidebar-open');
    });

    $('.navmenu-fixed-left').on('hidden.bs.offcanvas', function () {
        $('body').removeClass('sidebar-open');
    });
}, jQuery);