angular.module('dnecom.frontend.mobile')
    .directive('cartTimerMobile', function() {
        return {
            link: function(scope, element) {
                var timerTo = TemplateData.value('cartTimerDuration');

                function initialize() {
                    if (timerTo) {
                        var d = new Date();
                        d.setTime(d.getTime() + timerTo*1000);

                        // https://github.com/hilios/jQuery.countdown manual
                        $(element)
                            .countdown(d, function(event) {
                                $(this).html(
                                    '<span>' + event.strftime('%H') + '</span><span class="cart-timer-time-separator">:</span>' +
                                    '<span>' + event.strftime('%M') + '</span><span class="cart-timer-time-separator">:</span>' +
                                    '<span>' + event.strftime('%S') + '</span>'
                                );
                            });

                        setTimeout(function(){
                            $(element).parent().removeClass('open');
                        }, 5000);
                    } else {
                        $(element).closest('.cart-timer').hide();
                    }
                }

                initialize();
            }
        }
    });