angular
    .module('dnecom.frontend.mobile')
    .controller('ProductMobileController', ["$scope", function ($scope) {
        $scope.activeSlide = 0;

        $scope.slides = [];
        $scope.images = {};
        $scope.zooms = {};
        $scope.zoomOptions = {};
        $scope.savingsInPercents = TemplateData.value('savingsInPercents');
        $scope.watermark = {
            enabled: TemplateData.value('watermarkEnabled'),
            type: TemplateData.value('watermarkType'),
            contentType: TemplateData.value('watermarkContentType'),
            backgroundColor: TemplateData.value('watermarkBackgroundColor')
        };

        if (!$scope.product.variant) {
            $scope.product.variant = {
                price: 0
            };
        }

        $scope.product.variant.isFree = parseFloat($scope.product.variant.price) == 0;

        angular.forEach($scope.productGallery, function (image) {
            $scope.slides.push(image.media.thumbUrl);
        });

        angular.forEach($scope.product.gallery, function (image) {
            $scope.images[image.media.thumbUrl] = image.media.origUrl;
            $scope.zooms[image.media.thumbUrl] = false;
            $scope.zoomOptions[image.media.thumbUrl] = {zoomType: 'inner', cursor: 'crosshair', enabled: false};
        });

        var showProductAttributeImage = function (attributeValue) {

            if (attributeValue.images.length > 0) {
                var attributeValueImage = attributeValue.images[0];
                var found = false;

                $scope.product.gallery.forEach(function (productImage) {

                    if (productImage.media.id == attributeValueImage.id && !found) {

                        found = true;

                        if ($scope.slides.indexOf(productImage.media.thumbUrl) == -1) {
                            $scope.slides.pop();
                            $scope.slides.unshift(productImage.media.thumbUrl);
                        }

                        $scope.activeSlide = $scope.slides.indexOf(productImage.media.thumbUrl);
                    }
                });
            }
        };

        $scope.$on('product.imageable.selected', function ($event, attributeValue, attribute, product) {
            _.each($scope.attributes, function (attr) {
                if (attribute.id == attr.id) {
                    _.each(attr.values, function (value) {
                        if (value.id == attributeValue.id) {
                            showProductAttributeImage(value);
                        }
                    });
                }
            });
        });

        $scope.addZoom = function (slide) {
            $scope.zoomOptions[slide].enabled = true;
            console.log(slide, 'enabled');
            $scope.zooms[slide] = true;
            $scope.$broadcast('product.zoom.enabled', slide);
        };

        $scope.removeZoom = function (slide) {
            $scope.zoomOptions[slide].enabled = false;
            $scope.zooms[slide] = false;
        };
    }]);
