angular.module('dnecom.frontend.mobile')
    .controller('MobileMainPageFeaturedController', ["$scope", "GaUtils", function MobileMainPageFeaturedController($scope, GaUtils) {
        $scope.featured = TemplateData.value('featured');
        $scope.activeProduct = 0;

        $scope.trackClick = function (productData) {
            GaUtils.trackClick(productData, "homepage");
        };

        $scope.makeTrackData = function (product, index) {
            return GaUtils.makeTrackData(product, index);
        };

        $scope.nextProduct = function ($event) {
            $event.preventDefault();
            $scope.activeProduct = ($scope.activeProduct < $scope.featured.length - 1) ? $scope.activeProduct + 1 : 0;
        };

        $scope.prevProduct = function ($event) {
            $event.preventDefault();
            $scope.activeProduct = ($scope.activeProduct == 0) ? $scope.featured.length - 1 : $scope.activeProduct - 1;
        };

        $scope.isActive = function ($index) {
            return $scope.activeProduct == $index;
        };

        $scope.goToPage = function (page) {
            $scope.activeProduct = page;
        }
    }]);
