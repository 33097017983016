angular
    .module('dnecom.frontend.mobile')
    .controller('SearchController', ["$scope", "$http", "GaUtils", "$window", function ($scope, $http, GaUtils, $window) {
        $scope.products = TemplateData.value('products');
        $scope.currentAmount = 8;
        $scope.count = TemplateData.value('count');
        $scope.therm = TemplateData.value('therm');
        $scope.sorting = TemplateData.value('sorting');
        $scope.direction = TemplateData.value('direction');

        $scope.hasMore = function () {
            return $scope.currentAmount < $scope.count;
        }

        $scope.loadMore = function () {
            $http
                .get(Routing.generate('stores_fe_api_product_search', {
                    offset: $scope.currentAmount,
                    limit: 8,
                    query: $scope.therm,
                    sorting: $scope.sorting,
                    direction: $scope.direction,
                }))
                .then(function (response) {
                    if (response.data.success) {
                        angular.forEach(response.data.items, function (item) {
                            $scope.products.push(item);
                        });
                        $scope.currentAmount += parseInt(response.data.items.length);
                    }
                })
                .finally(function() {
                    $scope.loading = false;
                });
        };

        $scope.trackClick = function (product, position, $event) {
            GaUtils.trackClick(GaUtils.makeTrackData(product, position), "homepage");
            if ($event && $event.currentTarget && $event.currentTarget.href) {
                $window.location.href = $event.currentTarget.href;
            }
        }
    }])
;
