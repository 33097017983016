angular.module('dnecom.frontend.mobile')
    .directive('timerCountdown', ["TimerProvider", "$rootScope", "$timeout", "$translate", function(TimerProvider, $rootScope, $timeout, $translate) {
        return {
            scope: {},
            link: function(scope, element) {
                function addLZ(value) {
                    if (value < 10) {
                        value = '0' + value;
                    }

                    return value;
                };

                function initialize() {
                    var timerDate = TimerProvider.date();

                    if (!timerDate) {
                        $timeout(function() {
                            $rootScope.$broadcast('timer.cleanup');
                        });

                        return;
                    }

                    // can't use timerDate.format there, because it automatically adjusts timezone before conversion
                    var expirationTime = timerDate.year() + '/' + addLZ(timerDate.month() + 1) + '/' + addLZ(timerDate.date());
                    expirationTime += ' ' + addLZ(timerDate.hour()) + ':' + addLZ(timerDate.minute()) + ':' + addLZ(timerDate.second());

                    $(element)
                        .countdown(expirationTime)
                        .on('update.countdown', function(event) {
                            var countdownString = '<span class="countdown-row countdown-show4">';
                            countdownString += '<span class="countdown-section">' +
                                '<span class="countdown-amount">' + event.offset.totalDays +'</span>' +
                                '<span class="countdown-period">'+$translate.instant('product_page.days')+'</span>' +
                                '</span>';

                            countdownString += '<span class="countdown-section">' +
                                '<span class="countdown-amount">' + event.offset.hours + '</span>' +
                                '<span class="countdown-period">'+$translate.instant('product_page.hours')+'</span>' +
                                '</span>';

                            countdownString += '<span class="countdown-section">' +
                                '<span class="countdown-amount">' + event.offset.minutes + '</span>' +
                                '<span class="countdown-period">'+$translate.instant('product_page.minutes')+'</span>' +
                                '</span>';

                            countdownString += '<span class="countdown-section">' +
                                '<span class="countdown-amount">' + event.offset.seconds + '</span>' +
                                '<span class="countdown-period">'+$translate.instant('product_page.seconds')+'</span>' +
                                '</span>' +
                                '</span>';


                            $(element).html(countdownString);
                        })
                        .on('finish.countdown', function(event) {
                            $(element).countdown('stop');

                            TimerProvider.expire();

                            if (!TimerProvider.repeating()) {
                                $timeout(function() {
                                    $rootScope.$broadcast('timer.cleanup');
                                });

                                return;
                            }

                            initialize();
                        })
                    ;
                }

                initialize();
            }
        }
    }]);
