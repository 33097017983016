angular.module('dnecom.frontend.mobile')
    .controller('MobileStoreController', MobileStoreController);

MobileStoreController.$inject = [
    '$scope'
];

function MobileStoreController( $scope) {
    const msc = this;
    msc.menuOpened = false;

    $scope.$on('search-opened', function () {
        msc.menuOpened = false;
    });
}
