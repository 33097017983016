angular
    .module('dnecom.frontend.mobile')
    .controller('PaymentController', ["$scope", "$http", "$timeout", "CartHash", function ($scope, $http, $timeout, CartHash) {
        $scope.cartHash = CartHash.get();
        let vm = this;

        $scope.paymentMethod = TemplateData.value('paymentMethod');
        $scope.shippingServicesData = TemplateData.value('shippingServicesData');
        $scope.hasShippingPaymentMethods = TemplateData.value('hasShippingPaymentMethods');
        $scope.hasPickupPaymentMethods = TemplateData.value('hasPickupPaymentMethods');
        $scope.hasCashDeliveryPaymentMethods = TemplateData.value('hasCashDeliveryPaymentMethods');
        $scope.hasCashPickupPaymentMethods = TemplateData.value('hasCashPickupPaymentMethods');
        $scope.hasPayNowPaymentMethods = TemplateData.value('hasPayNowPaymentMethods');
        $scope.paymentMethods = TemplateData.value('paymentMethods');
        $scope.canBeWithoutAddress = TemplateData.value('canBeWithoutAddress');
        $scope.selectedDelivery = 'delivery';
        vm.submitDisabled = false;

        if ($scope.paymentMethods.includes('default-pod')) {
            vm.paymentMethodsGroup = 'cod';
            vm.paymentMethod = 'default-pod';
        } else {
            vm.paymentMethodsGroup = 'pay_now';
            vm.paymentMethod = $scope.paymentMethods.find(element => element !== 'default-pop' && element !== 'default-pod');
        }
        if (
            $scope.shippingServicesData['pickup'].length
            && !['standard', 'expedited', 'premium'].includes($scope.shippingServicesData.selected)
        ) {
            $scope.selectedDelivery = 'pickup';
            if ($scope.paymentMethods.includes('default-pop')) {
                vm.paymentMethodsGroup = 'cod';
                vm.paymentMethod = 'default-pop';
            } else {
                vm.paymentMethodsGroup = 'pay_now';
                vm.paymentMethod = $scope.paymentMethods.find(element => element !== 'default-pop' && element !== 'default-pod');
            }
        }


        $scope.useSingleAddress = true;

        $scope.products = [];

        $scope.cart = TemplateData.value('cart');

        if (!$scope.cart.shippingAddress) {
            $scope.cart.shippingAddress = {
                name    : '',
                street  : '',
                rest    : '',
                country : '',
                state   : '',
                city    : '',
                zip     : '',
                phone   : ''
            };
        }

        $scope.customer = {
            name: $scope.cart.shippingAddress.name,
            street: $scope.cart.shippingAddress.street,
            rest: $scope.cart.shippingAddress.rest,
            country: $scope.cart.shippingAddress.country,
            state: $scope.cart.shippingAddress.state,
            city: $scope.cart.shippingAddress.city,
            zip: $scope.cart.shippingAddress.zip,
            phone: $scope.cart.shippingAddress.phone
        };

        $scope.defaultCustomer = angular.copy($scope.customer);

        $scope.displayPaymentInfo = {
            shippingAddress: false,
            shippingMethod: false,
            orderSummary: false
        };

        $scope.togglePaymentInfo = function (key) {
            $scope.displayPaymentInfo[key] = !$scope.displayPaymentInfo[key];
        };

        $scope.isPaymentInfoVisible = function (key) {
            return $scope.displayPaymentInfo[key];
        };

        $scope.toggleBillingAddress = function () {
            $scope.useSingleAddress = !$scope.useSingleAddress;

            if ($scope.useSingleAddress) {
                $scope.customer = angular.copy($scope.defaultCustomer);
            }
        };

        $scope.isStateAvailable = function (countryCode) {
            if (!countryCode) {
                return false;
            }

            var states = TemplateData.value('states');

            return states.hasOwnProperty(countryCode);
        };

        $scope.isStateInCountry = function (countryCode, stateCode, stateName) {
            if (!countryCode) {
                return false;
            }

            var states = TemplateData.value('states');

            return states.hasOwnProperty(countryCode)
                && states[countryCode].hasOwnProperty(stateCode)
                && states[countryCode][stateCode] == stateName;
        };

        $scope.onAddressChanged = function(text) {
            $scope.customer.street = text;
        };

        $scope.onAddressSuggestionSelected = function(address) {
            if (!address || !address.originalObject) {
                return;
            }

            $scope.$broadcast('angucomplete-alt:changeInput', 'addressField', address.originalObject.street_line);

            function fillData() {
                $scope.customer.street = address.originalObject.street_line;
                $scope.customer.city = address.originalObject.city;
                $scope.customer.state = address.originalObject.state;
                $scope.customer.country = 'US';

                $scope.$broadcast('angucomplete-alt:changeInput', 'addressField', $scope.customer.street);
            }

            lookupZip(address.originalObject.city, address.originalObject.state).then(function(response) {
                if (response.data.success) {
                    $scope.customer.zip = response.data.code;
                }

                fillData();
            }, function() {
                fillData();
            });
        };

        var lookupZip = function (city, state) {
            return $http.get(Routing.generate('stores_fe_api_customer_address_zip_lookup', {
                'city': city,
                'state': state
            }));
        };

        vm.displayDelivery = function () {
            return $scope.hasShippingPaymentMethods && $scope.shippingServicesData['delivery'].length;
        }

        vm.displayPickup = function () {
            return $scope.hasPickupPaymentMethods && $scope.shippingServicesData['pickup'].length;
        }

        vm.submitMobileOrder = function () {
            vm.submitDisabled = true;
            $scope.$broadcast('mobile-order-submit', {
                pm: vm.paymentMethod,
                cart: $scope.cart,
                customer: $scope.customer
            });
        }

        $scope.$on('submit-enabled', function () {
            vm.submitDisabled = false;
        })

        $scope.$watch('vm', function (newVal, oldVal) {
            if (newVal.paymentMethodsGroup !== oldVal.paymentMethodsGroup) {
                if (newVal.paymentMethodsGroup == 'cod') {
                    vm.paymentMethod = vm.selectedDelivery == 'pickup' ? 'default-pop' : 'default-pod';
                } else {
                    vm.paymentMethod = $scope.paymentMethods.find(element => element !== 'default-pop' && element !== 'default-pod');
                }
            }
        }, true);
    }])
;
