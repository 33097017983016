angular.module('dnecom.frontend.mobile')
    .directive('addToCartMobile', ["$window", "Cart", "ProductFrontendUtils", "$http", "$timeout", function($window, Cart, ProductFrontendUtils, $http, $timeout) {
        return {
            scope: {
                product: '=',
                productCombination: '=',
                productAttributes: '=',
                errors: '='
            },
            link: function(scope, element) {
                var storeCurrency = _.get(window, 'ies.store.currency.storeCode');
                var addingInProgress = false;

                element.on('click', function(e) {
                    if (addingInProgress) {
                        return;
                    }

                    addingInProgress = true;

                    e.preventDefault();

                    if (scope.product.hasAttributes) {
                        $window.location.href = ProductFrontendUtils.url(scope.product, true);
                        return;
                    }

                    var error = false;

                    _.each(scope.productAttributes, function (attribute) {
                        if (attribute.values.length > 0) {
                            if (!scope.productCombination[attribute.id] || !scope.productCombination[attribute.id].id) {
                                $timeout(function () {
                                    scope.errors[attribute.id] = true;
                                });
                                error = true;
                            }
                        }
                    });

                    if (error) {
                        addingInProgress = false;
                        return;
                    }

                    var selected = {};

                    _.each(scope.productCombination, function (value, attributeId) {
                        if (value && value.id) {
                            selected[attributeId] = value.id;
                        }
                    });

                    $http
                        .post(
                            Routing.generate('stores_fe_api_product_configurator_validate', { productId: scope.product.id }),
                            selected
                        )
                        .then(function (response) {
                            if (response.data.success) {
                                Cart.addItem(scope.product.id, response.data.variant.id, 1).then(function () {
                                    addingInProgress = false;

                                    if (typeof gaplugins != "undefined" && typeof gaplugins.EC == "function") {
                                        var params = {
                                            "id": response.data.variant.sku,
                                            "name": scope.product.title,
                                            "price": response.data.variant.price,
                                            "quantity": 1
                                        };

                                        if (response.data.variant.title != scope.product.title) {
                                            params.variant = response.data.variant.title;
                                        }

                                        ga("ec:addProduct", params);
                                        ga("ec:setAction", "add");
                                        ga("send", "event", "detail view", "click", "addToCart");
                                    }

                                    if (typeof(fbq) == 'function') {
                                        var fbqData = {
                                            currency: storeCurrency.toUpperCase(),
                                            value: parseFloat(response.data.variant.price)
                                        };
                                        if (response.data.variant.sku) {
                                            fbqData.content_ids = [response.data.variant.sku];
                                            fbqData.content_type = 'product';
                                        }
                                        fbq('track', 'AddToCart', fbqData);
                                    }
                                }, function () {
                                    addingInProgress = false;
                                    console.log('Failed to add to cart.');
                                });
                            } else {
                                addingInProgress = false;
                                scope.$emit('addToCart.combination.error');
                            }
                        }, function () {
                            addingInProgress = false;
                            scope.$emit('addToCart.combination.error');
                        });
                });

                scope.$on('$destroy', function() {
                    element.off('click');
                });
            }
        }
    }]);
